import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@material-ui/lab";
import { Save } from "@material-ui/icons";
import * as yup from "yup";
import {
  Alert,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

const ODYSSEAL_ROLES = {
  ROLE_TRAINER: "ROLE_TRAINER",
  ROLE_TRAINED: "ROLE_TRAINED",
  ROLE_ADMIN: "ROLE_ADMIN",
  NO_ROLE: "NO_ROLE",
};

const ODYSSEAL_ROLES_LABELS = {
  [ODYSSEAL_ROLES.ROLE_TRAINED]: "Formée",
  [ODYSSEAL_ROLES.ROLE_TRAINER]: "Formateur",
  [ODYSSEAL_ROLES.ROLE_ADMIN]: "Administrateur",
  [ODYSSEAL_ROLES.NO_ROLE]: "Aucun",
};

const schema = yup.object().shape({
  mappings: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        mappedRole: yup.string(),
      }),
    )
    .test(
      "check on admin",
      "Vous devez sélectionnez au moins un administrateur",
      (values) =>
        values.some((item) => item.mappedRole === ODYSSEAL_ROLES.ROLE_ADMIN),
    ),
});

const RolesMappingsForm = ({ profiles, onSubmit }) => {
  const form = useForm({
    defaultValues: {
      mappings: profiles.map((profile) => ({
        ...profile,
        mappedRole: profile.mappedRole ?? ODYSSEAL_ROLES.NO_ROLE,
      })),
    },
    resolver: yupResolver(schema),
  });

  const mappings = form.watch("mappings");

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Profiles Hubnup</TableCell>
              <TableCell>Profile Odysseal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappings.map((field, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography>{field.title} </Typography>
                </TableCell>
                <TableCell>
                  <Controller
                    name={`mappings.${index}.mappedRole`}
                    control={form.control}
                    render={({ field }) => (
                      <RadioGroup {...field} row>
                        {Object.entries(ODYSSEAL_ROLES_LABELS).map(
                          ([role, label]) => (
                            <FormControlLabel
                              value={role}
                              label={label}
                              control={<Radio />}
                              disabled={form.formState.isSubmitting}
                            />
                          ),
                        )}
                      </RadioGroup>
                    )}
                  ></Controller>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {form.formState.errors.mappings && (
        <Alert title="Attention" severity="error">
          {form.formState.errors.mappings.message}
        </Alert>
      )}
      <Box display="flex" justifyContent="center" py="1rem">
        <LoadingButton
          variant="contained"
          loading={form.formState.isSubmitting}
          startIcon={<Save />}
          type="submit"
        >
          Enregistrer
        </LoadingButton>
      </Box>
    </form>
  );
};

export default RolesMappingsForm;
